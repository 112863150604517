import Alpine from 'alpinejs'
import collapse from '@alpinejs/collapse'
import 'lazysizes'
import {
  AlarmFatigue,
  Caution,
  CountUp,
  Circles,
  EventTimeline,
  Industries,
  Kugelblitz,
  Nis2,
  Pacman,
  Parallax,
  Perks,
  PerksHero,
} from './animations'
import Swup from 'swup'
import HeadPlugin from '@swup/head-plugin'
import BodyClassPlugin from '@swup/body-class-plugin'
import ScrollPlugin from '@swup/scroll-plugin'
import ScriptsPlugin from '@swup/scripts-plugin'
import Plausible from 'plausible-tracker'
import FileDownloads from './plausible/file-downloads.js'

window.Alpine = Alpine
const $pageTransition = document.querySelector('[data-page-transition]')

Alpine.plugin(collapse)

const animations = []

// set domain for Plausible tracking
const plausible = Plausible({
  domain: 'cybersense.de',
})

Alpine.magic('plausible', () => plausible)

// track page view on initial loading of page
plausible.trackPageview()

console.log(plausible)

// Trigger auto-downloads
// Do not move further down, as it needs to run before Swup to not cause a page transition
const $downloadLink = document.querySelector('a[data-auto-download]')

if ($downloadLink) {
  const hrefWithoutQuery = $downloadLink && $downloadLink.href && $downloadLink.href.split('?')[0]
  const path = hrefWithoutQuery.split('/')
  path.pop()
  plausible.trackEvent('File Download', { props: { url: path.join('/') }})
  $downloadLink.click();
}

function openContactFormInDrawer(e) {
  e.preventDefault()

  let linkUrl = new URL(e.currentTarget.getAttribute('href'))
  let formUrl = new URL(window.location.origin + '/' + Alpine.store('App').forms.contact.url)

  // Copy linkUrl params to formUrl params
  if (linkUrl.searchParams.entries()) {
    for (let [key, value] of linkUrl.searchParams.entries()) {
      formUrl.searchParams.set(key, value)
    }
  }

  this.dispatchEvent(new CustomEvent('open-drawer', { bubbles: true, detail: {
    url: formUrl,
    title: Alpine.store('App').forms.contact.title,
  }}))
}

function getAllContactLinks() {
  return document.querySelectorAll(`[href*="${Alpine.store('App').contactPage}"]`)
}

function setupListeners() {
  // Catch all clicks on contact page links and trigger drawer instead
  // Disable swup handling
  for (let $link of getAllContactLinks()) {
    $link.setAttribute('data-no-swup', true)
    $link.addEventListener('click', openContactFormInDrawer)
  }

  FileDownloads.watch(plausible)
}

function tearDownListeners() {
  for (let $link of getAllContactLinks()) {
    $link.removeAttribute('data-no-swup')
    $link.removeEventListener('click', openContactFormInDrawer)
    FileDownloads.unwatch(plausible)
  }
}

function animateAllTheThings () {
  CountUp('[data-count-up]', { language: Alpine.store('App').language })
  Circles('[data-animation=circles]')
  Caution('[data-animation=caution]')
  Industries('[data-animation=industries]')
  AlarmFatigue('[data-animation=alarm-fatigue]')
  Kugelblitz('[data-animation=kugelblitz]')
  Nis2('[data-animation=nis2]')
  Pacman('[data-animation=pacman]')
  Perks('[data-animation=perks]')
  PerksHero('[data-animation=perks-hero]')
  Parallax()

  // Animations that register DOM events themselves
  let eventTimeline = new EventTimeline('[data-animation="event-timeline"]')
  animations.push(eventTimeline)
  eventTimeline.init()
}

function destroyAllTheAnimations() {
  for (let index in animations) {
    animations[index].destroy()
    delete animations[index]
  }
}

Alpine.start()
animateAllTheThings()
setupListeners()

const swup = new Swup({
  containers: ['#swup', '#swup-page-header', '#swup-main-menu-mobile', '#swup-page-footer', '#swup-sticky-element'],
  animationSelector: '[class*="swup-transition-"]',
  plugins: [
    new BodyClassPlugin(),
    new HeadPlugin(),
    new ScrollPlugin({
      doScrollingRightAway: true,
      animateScroll: false,
    }),
    new ScriptsPlugin({
      header: true,
      body: false,
    }),
  ]
})

swup.on('animationOutStart', () => {
  if ($pageTransition) {
    $pageTransition.style.zIndex = '1'
  }
})

swup.on('animationOutDone', () => {
  if ($pageTransition) {
    $pageTransition.style.zIndex = ''
  }
})

// Destroy animations that might be still be running when the user navigates to the next page
swup.on('transitionStart', () => {
  destroyAllTheAnimations()
})

swup.on('contentReplaced', () => {
  // track pageview on page change through swup
  plausible.trackPageview()
  // Function is defined in 'header' snippet and will be evaluated on each page load.
  // This is handled by the Swup Scripts Plugin
  window.initStore()
  // Reinitialize all listeners (e.g. click listeners)
  tearDownListeners()
  setupListeners()
})

swup.on('transitionEnd', () => {
  // Initialize animations after page transition has ended
  // Otherwise, the page transition may cover other animations
  animateAllTheThings()
})

